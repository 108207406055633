import styled from 'styled-components';

export const SaleBadge = ({fontSize = 14, children}: ISaleBadgeProps) => {
  return (
    <StyledBadge fontSize={fontSize}>-{children}%</StyledBadge>
  );
};

interface ISaleBadgeProps {
  fontSize?: number;
  children?: number | string;
}

const StyledBadge = styled.div<Pick<ISaleBadgeProps, 'fontSize'>>`
color: #F0158B;
border: 1.5px dashed #F0158B;
padding: 5px 10px;
border-radius: 4px;
font-size: ${({fontSize}) => fontSize}px;
vertical-align: middle;
box-sizing: border-box;
text-align: center;
font-family: Minecraft;
cursor: default;
`;