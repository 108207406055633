import { usePollingEffect } from "../../hooks/usePollingEffect";
import { PlayerCounterWrapper } from "./styles";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../../contexts";

export const PlayerCounter = observer(() => {
  const {serverStore} = useRootStore();
  usePollingEffect(serverStore.updateServerStatus);

  return (
    <PlayerCounterWrapper>
      <a
        href="https://mineserv.top/mdrserver"
        className="mn-srv-btn mn-srv-btn--online"
      >
        <span className="mn-srv-btn__icon">
          <span>
            <svg viewBox="0 0 36 36">
              <path
                d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                fill="none"
                stroke="#fff"
                stroke-width="3"
                stroke-linecap="round"
                stroke-dasharray={`${serverStore.MainServerStatus?.players?.online}, ${serverStore.MainServerStatus?.players?.max}`}
              ></path>
            </svg>
            <span>{serverStore.MainServerStatus?.players?.online}</span>
          </span>
        </span>
        <span className="mn-srv-btn__text">
          <span>MDR</span>{" "}
          <p>
            Игроков {serverStore.MainServerStatus?.players?.online} из {serverStore.MainServerStatus?.players?.max}
          </p>
        </span>
      </a>
    </PlayerCounterWrapper>
  );
});
