import { action, makeObservable, observable } from "mobx";
import { API } from "../api";
import { IServer } from "types";

export class ServerStore {

    ServersStatus: IServer[] | undefined;
    MainServerStatus: IServer | undefined;
    constructor() {
        makeObservable(this, 
        { 
            ServersStatus: observable,
            MainServerStatus: observable,
            updateServerStatus: action.bound
        });
    }

    async updateServerStatus() {
        const statusResult = (await API.get('/api/server/status')).data as IServer[];
        this.MainServerStatus = statusResult.find((server) => !!server.IsMain);
        this.ServersStatus = statusResult;
    }
}