import styled from 'styled-components';
import {Button as StyledButton} from '../Button';
import {PriceBadge} from '../PriceBadge';
import { motion } from 'framer-motion';

export const ProductImage = styled.img`
  width: 190.48px;
  height: 190.54px;
  border-radius: 0px 0px 10px 0px;
  position: absolute;
  right: 0;
  bottom: 0;
  border: none;
  transition: all 0.3s;
`;

export const Background = styled(motion.div)`
  -webkit-font-smoothing: subpixel-antialiased;
  background-color: #1b1b20;
  transition: transform 0.25s ease;
  position: relative;
  color: white;
  border-radius: 10px;
  width: 350px;
  min-height: 250px;
  margin: 5px 5px;
  backface-visibility: hidden;
  cursor: pointer;
  &:hover{
    z-index: 100;
  }

`;
export const Wrapper = styled.div`
  margin: 10px 15px;
`;

export const ProductShortDescription = styled.p`
  text-align: left;
  width: 200px;
  height: max-content;
  max-height: 101px;
  overflow: hidden;
  padding: 10px 0px;
  margin: 0;
  margin-bottom: 10px;
  box-sizing: border-box;
  font-family: 'Raleway';
  font-size: 12.5px;
  line-height: 13px;
  white-space: pre-wrap;
  .minecraft{
    font-family: Minecraft;
    font-size: 12px;
  }
`;
export const ProductHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  font-family: 'Raleway';
  h2 {
    font-family: Minecraft;
    font-size: 18px;
    padding: 0;
    margin: 0;
  }
`;
export const Button = styled(StyledButton)`
  position: absolute;
  left: 15px;
  bottom: 20px;
`;

export const StyledPriceBadge = styled(PriceBadge)`
  position: absolute;
  left: 15px;
  bottom: 30px;
`;

export const Preloader = styled.div`
  position: relative;
  background: linear-gradient(
    -45deg,
    #1b1b20,
    #1b1b20,
    #1b1b20,
    #1b1b20,
    #312f7d,
    #1b1b20,
    #1b1b20,
    #1b1b20
  );
  background-size: 800% 600%;
  animation: gradient 1s infinite;
  border-radius: 10px;
  min-width: 350px;
  min-height: 250px;
  margin: 5px 5px;
  @keyframes gradient {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;
