import {library} from '@fortawesome/fontawesome-svg-core';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';
import './index.css';
import {RouterProvider} from 'react-router';
import {RootStoreContext} from './contexts';
import { router} from './router';
import { rootStore } from './router';

function App() {
  library.add(fab, fas, far);
  return (
    <RootStoreContext.Provider value={rootStore}>
      <RouterProvider router={router} />
    </RootStoreContext.Provider>
  );
}

export {App};

